import { Text, type UseModalState } from "@clipboard-health/ui-react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import { getColorForTitleSegment } from "./util";

interface TitleSegment {
  text: string;
  color?: string;
}

export type PostClockInPopupConfig =
  | {
      enabled: false;
    }
  | {
      enabled: true;
      title: TitleSegment[];
      description: string[];
    };

interface PostClockInModalProps {
  modalState: UseModalState;
  onContinue?: () => void;
  title: TitleSegment[];
  description: string[];
}

export function PostClockInModal(props: PostClockInModalProps) {
  const { modalState, onContinue, title, description } = props;

  return (
    <Dialog
      disableEscapeKeyDown
      open={modalState.modalIsOpen}
      aria-labelledby="post-clock-in-dialog-title"
      sx={{
        "& .MuiDialog-paper": {
          overflow: "visible",
          width: 340,
          display: "flex",
          flexBasis: 340,
          flexShrink: 1,
        },
      }}
      onClose={(_event, reason) => {
        if (reason !== "backdropClick") {
          modalState.closeModal();
        }
      }}
    >
      <DialogContent
        sx={{ pt: 5, pb: 4, borderRadius: 2, position: "relative", overflow: "visible" }}
      >
        <Box
          position="absolute"
          top={-120}
          left={22}
          right={0}
          zIndex={1000}
          display="flex"
          justifyContent="center"
          overflow="visible"
        >
          <img src="assets/logo/clipboard-bunny.png" alt="" height={130} />
        </Box>
        <Text bold variant="h4">
          {title.map((segment) => (
            <span key={segment.text} style={{ color: getColorForTitleSegment(segment.color) }}>
              {segment.text}{" "}
            </span>
          ))}
        </Text>

        <List sx={{ p: 0, pl: 2, pr: 3, listStyleType: "disc" }}>
          {description.map((line) => (
            <ListItem key={line} sx={{ mt: 2, p: 0, display: "list-item" }}>
              <ListItemText sx={{ p: 0 }}>{line}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>

      <DialogActions
        sx={{
          p: 0,
          borderTop: "1px solid #000",
          borderBottomLeftRadius: 16,
          borderBottomRightRadius: 16,
        }}
      >
        <Button
          fullWidth
          variant="text"
          sx={{ color: "#000", fontSize: "1rem" }}
          onClick={() => {
            modalState.closeModal();
            onContinue?.();
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
