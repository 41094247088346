import { Text } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

interface ClockOutPaymentMessageDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ClockOutPaymentMessageDialog(props: ClockOutPaymentMessageDialogProps) {
  const { isOpen, onClose } = props;

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxWidth: 400,
          margin: 2,
        },
      }}
      onClose={onClose}
    >
      <DialogContent sx={{ textAlign: "center" }}>
        <Text variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          Shift completed!
        </Text>

        <Text variant="body1" sx={{ color: "text.secondary" }}>
          You have been paid. If you would like to view your InstantPay for this shift, please visit
          the Payroll page.
        </Text>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          fullWidth
          variant="contained"
          sx={{
            textTransform: "none",
            borderRadius: 1,
          }}
          onClick={onClose}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
