import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogActions, DialogContent, Stack } from "@mui/material";
import { getScoreType } from "@src/appV2/AttendanceScore/utils";
import pluralize from "pluralize";

interface Props {
  modalState: UseModalState;
  onContinue?: () => void;
  bonusPoints: number;
  delayInHours: number;
  isClipboardScoreEnabled: boolean;
  isReliabilityRankingEnabled: boolean;
}

export function PositiveReviewPolicyModal(props: Props) {
  const {
    modalState,
    onContinue,
    bonusPoints,
    delayInHours,
    isClipboardScoreEnabled,
    isReliabilityRankingEnabled,
  } = props;
  const scoreType = getScoreType({
    isReliabilityRankingExperimentEnabled: isReliabilityRankingEnabled,
    isClipboardScoreEnabled,
  });

  return (
    <Dialog
      disableEscapeKeyDown
      open={modalState.modalIsOpen}
      aria-labelledby="attendance-dialog-title"
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "16px",
        },
      }}
      onClose={(_event, reason) => {
        if (reason !== "backdropClick") {
          modalState.closeModal();
        }
      }}
    >
      <DialogContent sx={{ pt: 3, pb: 2 }}>
        <Stack gap={2}>
          <Text bold variant="h4">
            Thanks for clocking in. Receive a{" "}
            <span style={{ color: "#4CAF50" }}>+{bonusPoints}</span> {scoreType} bonus on this shift
            after getting an excellent review from a supervisor you&apos;ve worked closely with!
          </Text>

          <Text variant="body2">
            Excellence bonuses will be issued{" "}
            {delayInHours > 0 ? `${delayInHours} ${pluralize("hour", delayInHours)}` : "shortly"}{" "}
            after the shift ends.
          </Text>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            modalState.closeModal();
            onContinue?.();
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
