import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import { IconButton } from "@mui/material";
import { getPlacementChatPath } from "@src/appV2/redesign/Placements/paths";
import { useHistory } from "react-router-dom";

interface InterviewChatWithWorkplaceProps {
  placementId: string;
  workplaceId: string;
}

export function InterviewChatWithWorkplace(props: InterviewChatWithWorkplaceProps) {
  const { placementId, workplaceId } = props;
  const history = useHistory();

  return (
    <IconButton
      size="small"
      sx={{ padding: 0 }}
      onClick={async (event) => {
        event.stopPropagation();

        history.push(
          getPlacementChatPath(
            placementId,
            workplaceId,
            "chat-with-placement-candidate-via-my-shifts"
          )
        );
      }}
    >
      <QuestionAnswerRoundedIcon sx={{ color: (theme) => theme.palette.grey[900] }} />
    </IconButton>
  );
}
