import { useModalState } from "@clipboard-health/ui-react";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
} from "@mui/material";
import { type SyntheticEvent } from "react";

export function InterviewIncentiveDialog() {
  const interviewIncentiveDialogState = useModalState();

  return (
    <>
      <IconButton
        size="small"
        edge="end"
        aria-label="Mandatory Lunch Break Button"
        sx={{
          paddingRight: 0,
          paddingY: 0,
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          interviewIncentiveDialogState.openModal();
        }}
      >
        <InfoIcon color="primary" fontSize="small" />
      </IconButton>
      <Dialog
        open={interviewIncentiveDialogState.modalIsOpen}
        onClose={(event) => {
          (event as SyntheticEvent)?.stopPropagation?.();
          interviewIncentiveDialogState.closeModal();
        }}
      >
        <DialogContent>
          <DialogContentText align="center">
            You&apos;ll receive $20 for travel expenses once your attendance is confirmed
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: 0 }}>
          <Button
            fullWidth
            onClick={(event) => {
              event.stopPropagation();
              interviewIncentiveDialogState.closeModal();
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
