const PostClockInPopupColors = {
  BLACK: "#000000",
  BLUE: "#0C1C43",
  GREEN: "#009e0f",
  YELLOW: "#f1c232",
} as const;

export function isPostClockInPopupColor(
  color?: string
): color is keyof typeof PostClockInPopupColors {
  return color ? Object.keys(PostClockInPopupColors).includes(color.toUpperCase()) : false;
}

export function getColorForTitleSegment(color?: string): string {
  const upperColor = color?.toUpperCase();

  if (!isPostClockInPopupColor(upperColor)) {
    return PostClockInPopupColors.BLACK;
  }

  return PostClockInPopupColors[upperColor];
}
